import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

// Components
import { useTheme } from '@material-ui/core';
import {
    DashboardIcon,
    FollowUpIcon,
    IdentityManagementIcon,
    IncidentIcon,
    PostmortemIcon,
    SettingsIcon,
    NotebookIcon,
    SloIcon,
    CommsFlowIcon,
    WorkflowsIcon
} from '@blamelesshq/blameless-components';

// Constants
import {
    FOLLOWUP_ACTIONS_URL,
    IDENTITY_MANAGEMENT_URL,
    INCIDENTS_URL,
    POSTMORTEM_URL,
    RELIABILITY_INSIGHTS_URL,
    SETTINGS_URL,
    RUNBOOKS_URL,
    SLO_MANAGE_URL,
    USER_JOURNEY_URL,
    COMMS_FLOW_URL,
    FLOWS,
    WORKFLOWS_URL
} from 'src/constants/urls';
import { MENU_SECTIONS_TYPES } from 'src/constants/common';
import { ROLE_COMPONENTS } from 'src/constants/user-permissions';

const messages = defineMessages({
    postmortems: {
        id: 'sideNav.menu.postmortems',
        defaultMessage: 'Retrospectives',
        description: 'The text for the postmortems menu'
    },
    slo: {
        id: 'sideNav.menu.slo',
        defaultMessage: 'SLO Manager',
        description: 'The text for the slo manager menu name'
    }
});

interface IProps {
    activeItem: string;
}

export default ({ activeItem }: IProps) => {
    const {
        slosEnabled,
        postmortemListPage,
        enableIdentityManagementPage,
        enableRunbooks,
        enableCommsFlowUi,
        workflowsM1
    } = useSelector((state: RootState) => state.launchDarkly);
    const theme = useTheme();
    const { formatMessage } = useIntl();

    const isMenuItemActive = (route: string) => {
        return activeItem.split('/')[1] === route.split('/')[1] ? '#C8B9FE' : theme.colors.ui.white;
    };

    const commonSections: INavigation.INavigationSection[] = [
        {
            key: 'incidents',
            name: 'Incidents',
            route: INCIDENTS_URL,
            icon: <IncidentIcon fillColor={isMenuItemActive(INCIDENTS_URL)} />,
            enabled: true,
            component: ROLE_COMPONENTS.INCIDENT
        },
        {
            key: 'postmortems',
            name: 'Retrospectives',
            label: formatMessage(messages.postmortems),
            route: POSTMORTEM_URL,
            icon: <PostmortemIcon fillColor={isMenuItemActive(POSTMORTEM_URL)} />,
            enabled: postmortemListPage,
            component: ROLE_COMPONENTS.INCIDENT
        },
        {
            key: 'manage',
            name: formatMessage(messages.slo),
            route: `${SLO_MANAGE_URL}${USER_JOURNEY_URL}`,
            icon: <SloIcon fillColor={isMenuItemActive(SLO_MANAGE_URL)} />,
            enabled: slosEnabled,
            component: ROLE_COMPONENTS.SLO
        },
        {
            key: 'reliability_insight',
            name: 'Reliability Insights',
            route: RELIABILITY_INSIGHTS_URL,
            icon: <DashboardIcon fillColor={isMenuItemActive(RELIABILITY_INSIGHTS_URL)} />,
            enabled: true,
            component: ROLE_COMPONENTS.DASHBOARD
        },
        {
            key: 'runbooks',
            name: 'Runbook Documents',
            route: RUNBOOKS_URL,
            icon: <NotebookIcon fillColor={isMenuItemActive(RUNBOOKS_URL)} />,
            enabled: enableRunbooks,
            component: ROLE_COMPONENTS.RUNBOOKS
        },
        {
            key: 'followups',
            name: 'Follow-up Actions',
            route: FOLLOWUP_ACTIONS_URL,
            icon: <FollowUpIcon fillColor={isMenuItemActive(FOLLOWUP_ACTIONS_URL)} />,
            enabled: true,
            component: ROLE_COMPONENTS.INCIDENT
        },
        {
            key: 'workflows',
            name: 'Workflows',
            route: `${WORKFLOWS_URL}${FLOWS}`,
            icon: <WorkflowsIcon fillColor={isMenuItemActive(WORKFLOWS_URL)} />,
            enabled: workflowsM1,
            component: ROLE_COMPONENTS.WORKFLOWS
        },
        {
            key: 'comms_flow',
            name: 'CommsFlow',
            route: `${COMMS_FLOW_URL}${FLOWS}`,
            icon: <CommsFlowIcon fillColor={isMenuItemActive(COMMS_FLOW_URL)} />,
            enabled: enableCommsFlowUi,
            component: ROLE_COMPONENTS.COMMSFLOW
        },
        {
            key: 'identity_management',
            name: 'Identity Management',
            route: IDENTITY_MANAGEMENT_URL,
            icon: <IdentityManagementIcon fillColor={isMenuItemActive(IDENTITY_MANAGEMENT_URL)} />,
            enabled: enableIdentityManagementPage,
            component: ROLE_COMPONENTS.IDENTITY
        },
        {
            key: 'settings',
            name: 'Settings',
            route: SETTINGS_URL,
            icon: <SettingsIcon fillColor={isMenuItemActive(SETTINGS_URL)} />,
            enabled: true,
            component: ROLE_COMPONENTS.SETTING
        }
    ];

    // Each new set of sections config should be merged with commonSections
    // that way you only have to write new properties for this set
    return {
        [MENU_SECTIONS_TYPES.COMMON]: commonSections
    };
};
