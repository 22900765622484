export const SCHEMA_FIELDS: Record<string, string> = {
    INCIDENT_RESOLVED_IS_REQUIRED: 'org.postmortem.incident.resolved.is-required',
    JIRA_TICKET_PROJECT: 'org.integration.jira-incident-ticket-project',
    JIRA_TICKET_TYPE: 'org.integration.jira-incident-ticket-type',
    JIRA_REQUIRED_FIELDS: 'org.integration.jira-incident-required-fields',
    JIRA_PRIORITY: 'org.incident.jira-priority',
    JIRA_LABELS: 'org.incident.jira-labels',
    FOLLOWUP_SUMMARY: 'summary',
    FOLLOWUP_ASSIGNEE: 'assignee',
    FOLLOWUP_ACTION_TYPE: 'action-type',
    FOLLOWUP_ACTION_STATUS: 'action-status',
    FOLLOWUP_JIRA_TICKET_PROJECT: 'org.jira.project',
    FOLLOWUP_JIRA_ISSUE_TYPE: 'org.jira.issuetype',
    AUTO_ALERT_PAGERDUTY_SERVICE_IDS: 'org.integration.auto-trigger-alert.pagerduty-service-ids',
    AUTO_ASSIGN_SERVICENOW_SERVICE_IDS: 'org.integration.service-now-services-ids',
    PAGERDUTY_AUTO_TRIGGER: 'org.integration.pagerduty-auto-trigger',
    SERVICENOW_AUTO_TRIGGER: 'org.integration.service-now-auto-trigger',
    JIRA_AUTO_TRIGGER: 'org.integration.jira-auto-trigger',
    OPSGENIE_AUTO_TRIGGER: 'org.integration.opsgenie-auto-trigger',
    SERVICENOW_AUTO_ASSIGNMENT_TRIGGER: 'org.integration.service-now-auto-service-assignment',
    AUTOTASK_ACCOUNT_ID: 'org.incident.autotask-accountid',
    AUTOTASK_QUEUE_ID: 'org.incident.autotask-queueid',
    AUTOTASK_STATUS: 'org.incident.autotask-status',
    AUTOTASK_PROJECT_ID: 'org.incident.autotask-projectid',
    INCIDENT_VIA_WEBHOOK: 'org.incident.force-create-incident-via-webhook',
    INCIDENT_TOGGLE_AUTO_RECORDING_FOR_ZOOM: 'org.incident.toggle-auto-recording-for-zoom-integration',
    INCIDENT_NAMING_SCHEME: 'org.incident.naming-scheme',
    INCIDENT_NAMING_CUSTOM_SCHEME: 'org.incident.custom-naming-scheme',
    INCIDENT_NAMING_CUSTOM_SCHEME_AUTO_HYPEHNS: 'org.incident.custom-naming-scheme-auto-hyphens',
    STATUS: 'org.incident.status',
    INCIDENT_TYPE: 'org.incident.type',
    SEVERITY: 'org.incident.severity',
    INCIDENT_PRIVATE_SLACK_CHANNELS: 'org.incident.private',
    INCIDENT_INVITED_SLACK_GROUPS: 'org.incident.invited-slack-groups',
    INCIDENT_ANNOUNCEMNENT_SLACK_CHANNELS: 'org.incident.announcement-channels',
    INCIDENT_INVITED_MS_TEAMS_USERS: 'org.incident.invited-ms-teams-users',
    INCIDENT_ANNOUNCEMNENT_MS_TEAMS_GROUPS: 'org.incident.announcement-ms-groups',
    INCIDENT_ANNOUNCEMNENT_MS_TEAMS_CHANNELS: 'org.incident.announcement-ms-channels',
    INCIDENT_TOGGLE_SUMMARY: 'org.incident.toggle.summary',
    INCIDENT_MS_TEAMS_TEAM: 'org.incident.ms-teams-team',
    INCIDENT_CHECKLIST_TASK: 'org.incident.checklist-tasks',
    INCIDENT_TAG_CATEGORIES: 'org.incident.tag-categories',
    INCIDENT_AUTO_CREATOR_ROLE: 'org.incident.creator-to-role',
    INCIDENT_ELEVATED_ROLE: 'org.incident.elevated-role',
    INCIDENT_AUTO_ONCALL_AS_COMMANDER: 'org.incident.auto-assign-oncall-as-commander',
    INCIDENT_SLA_BUSINESS_DAYS: 'org.incident.use-business-days',
    INCIDENT_EXCLUDE_WEEKENDS: 'org.incident.exclude-weekends',
    INCIDENT_EXCLUDE_US_HOLIDAYS: 'org.incident.exclude-us-holidays',
    POSTMORTEM_ANALYSIS_TEMPLATE: 'org.postmortem.analysis-template',
    POSTMORTEM_CUSTOM_INPUT_FORM: 'org.postmortem.custom-input-form',
    POSTMORTEM_COMPLETION_SLA: 'org.postmortem.completion-sla',
    POSTMORTEM_IS_REQUIRED: 'org.postmortem.is-required',
    POSTMORTEM_COMPLETION_REMINDER: 'org.postmortem.completion-reminder',
    AUTOTASK_ENABLED: 'org.integration.autotask-enabled',
    AUTOTASK_USER_EMAIL: 'org.integration.autotask-user-email',
    AUTOTASK_USER_PASSWORD: 'org.integration.autotask-user-password',
    DATADOG_ENABLED: 'enabled',
    DATADOG_APP_KEY: 'applicationKey',
    DATADOG_API_KEY: 'apiKey',
    GOOGLE_CALENDAR_ENABLED: 'org.integration.google-calendar-enabled',
    JIRA_ENABLED: 'org.integration.jira-enabled',
    JIRA_BASE_URL: 'org.integration.jira-base-url',
    JIRA_AUTH_METHOD: 'org.integration.jira-auth-method',
    JIRA_USERNAME: 'org.integration.jira-username',
    JIRA_PASSWORD: 'org.integration.jira-password',
    JIRA_FOLLOWUP_TICKET_PROJECT: 'org.integration.jira-default-followup-ticket-project',
    JIRA_FOLLOWUP_TICKET_TYPE: 'org.integration.jira-default-followup-ticket-type',
    JIRA_ISSUE_LINK_TYPE: 'org.integration.jira-issue-link-type',
    JIRA_ROOT_CERT: 'org.integration.jira_root_cert',
    JIRA_CA_CERT: 'org.integration.jira_ca_cert',
    JIRA_CA_KEY: 'org.integration.jira_ca_key',
    JIRA_CONNECTIVITY_CHECK_ENABLED: 'org.integration.jira-connectivity-check-enabled',
    JIRA_CONNECTIVITY_CHECK_INTERVAL: 'org.integration.jira-connectivity-check-interval',
    JIRA_OAUTH_USER_EMAIL: 'org.integration.jira-username',
    JIRA_OAUTH_RSA_PRIVATE_KEY: 'org.integration.jira-oauth-rsa-private-key',
    JIRA_OAUTH_CONSUMER_KEY: 'org.integration.jira-oauth-consumer-key',
    OPSGENIE_ENABLED: 'org.integration.opsgenie-enabled',
    OPSGENIE_API_KEY: 'org.integration.opsgenie-api-key',
    OPSGENIE_INSTANCE_URL: 'org.integration.opsgenie-instance-url',
    OPSGENIE_SERVICES_IDS: 'org.integration.auto-trigger-alert.opsgenie-service-ids',
    PAGERDUTY_ENABLED: 'org.integration.pagerduty-enabled',
    PAGERDUTY_API_KEY: 'org.integration.pagerduty-api-key',
    PAGERDUTY_ALERT_RESPONDERS: 'org.integration.pagerduty-enable-responders',
    PAGERDUTY_ALERT_ESCALATION_POLICY: 'org.integration.pagerduty-enable-escalation-policy',
    PAGERDUTY_DEFAULT_ALERT_REQUESTER: 'org.integration.pagerduty-default-alert-requester',
    BACKSTAGE_ENABLED: 'org.integration.backstage-enabled',
    BACKSTAGE_URL: 'org.integration.backstage-url',
    PINGDOM_ENABLED: 'enabled',
    PINGDOM_API_TOKEN: 'apiToken',
    NEWRELIC_ENABLED: 'enabled',
    NEWRELIC_API_KEY: 'key',
    PROMETHEUS_ENABLED: 'enabled',
    PROMETHEUS_MODE: 'mode',
    PROMETHEUS_APP_URI: 'apiUrl',
    PROMETHEUS_USERNAME: 'username',
    PROMETHEUS_PASSWORD: 'password',
    PROMETHEUS_LAMBDA_URI: 'lambdaUrl',
    PROMETHEUS_LAMBDA_TOKEN: 'lambdaToken',
    PROMETHEUS_GCP_CLUSTER: 'gcp',
    PROMETHEUS_GRAFANA_URL: 'grafanaUrl',
    PROMETHEUS_GRAFANA_API_KEY: 'grafanaApiKey',
    APPDYNAMICS_ENABLED: 'org.integration.appdynamics-enabled',
    APPDYNAMICS_CONTROLLER_URL: 'org.integration.appdynamics-controller-url',
    APPDYNAMICS_USERNAME: 'org.integration.appdynamics-username',
    APPDYNAMICS_PASSWORD: 'org.integration.appdynamics-password',
    APPDYNAMICS_API_KEY: 'org.integration.appdynamics-api-key',
    GITHUB_ENABLED: 'org.integration.github-issues-enabled',
    GITHUB_ACCESS_TOKEN: 'org.integration.github-issues-access-token',
    GITHUB_OWNER: 'org.integration.github-issues-owner',
    GITHUB_REPO: 'org.integration.github-issues-repo',
    GITHUB_DEFAULT_LABELS: 'org.integration.github-issues-default-labels',
    SLACK_ENABLED: 'org.integration.slack-enabled',
    SLACK_BOT_TOKEN: 'org.integration.slack-bot-token',
    SLACK_WEB_TOKEN: 'org.integration.slack-web-token',
    SLACK_APP_ID: 'org.integration.slack-app-id',
    MSTEAMS_ENABLED: 'org.integration.msteams-enabled',
    MSTEAMS_TENANT_ID: 'org.integration.msteams-tenant-id',
    MSTEAMS_TENANT_NAME: 'org.integration.msteams-tenant-name',
    MSTEAMS_MEETING_ENABLED: 'org.integration.msteams-meeting-enabled',
    MSTEAMS_MEETING_TENANT_ID: 'org.integration.msteams-meeting-tenant-id',
    MSTEAMS_MEETING_USER_ID: 'org.integration.msteams-meeting-user-id',
    SNOW_ENABLED: 'org.integration.service-now-enabled',
    SNOW_PROBLEM_TICKET_AUTO_TRIGGER: 'org.integration.service-now.problem-ticket-auto-trigger',
    SNOW_INSTANCE_URL: 'org.integration.service-now-instance',
    SNOW_CLIENT_ID: 'org.integration.service-now-client-id',
    SNOW_CLIENT_SECRET: 'org.integration.service-now-client-secret',
    SNOW_USERNAME: 'org.integration.service-now-username',
    SNOW_PASSWORD: 'org.integration.service-now-password',
    SNOW_AUTH_METHOD: 'org.integration.service-now-auth-method',
    SNOW_SERVICE_ASSIGN_ENABLED: 'org.integration.snow-service-assignment',
    STATUSPAGE_ENABLED: 'org.integration.statuspage-enabled',
    STATUSPAGE_API_URL_PREFIX: 'org.integration.statuspage-api-url-prefix',
    STATUSPAGE_API_KEY: 'org.integration.statuspage-api-key',
    STATUSPAGE_PAGE_ID: 'org.integration.statuspage-page-id',
    ZOOM_ENABLED: 'org.integration.zoom-enabled',
    ZOOM_USER_EMAIL: 'org.integration.zoom-user-email',
    ZOOM_ACCOUNT_ID: 'org.integration.zoom-account-id',
    ZOOM_CLIENT_ID: 'org.integration.zoom-client-id',
    ZOOM_CLIENT_SECRET: 'org.integration.zoom-client-secret',
    ORG_NAME: 'org.organization-name',
    ORG_TIMEZONE: 'timezone',
    ORG_INCIDENT_TYPES: 'org.incident.types',
    ORG_INCIDENT_TYPES_ACTIVE: 'org.incident.types.active',
    ORG_INCIDENT_ROLES: 'org.incident.roles',
    ORG_BOT_COMPACT_VIEW: 'org.bot.compact_view',
    ORG_BOT_SILENT: 'org.bot.silent_mode',
    ORG_SEV0_LABEL: 'org.incident.sev0-label',
    ORG_SEV1_LABEL: 'org.incident.sev1-label',
    ORG_SEV2_LABEL: 'org.incident.sev2-label',
    ORG_SEV3_LABEL: 'org.incident.sev3-label',
    ORG_PM_COMPLETION_REMINDER: 'org.postmortem.completion-reminder',
    ORG_PM_CUSTOM_TAB_NAME: 'org.postmortem.custom-tab-name',
    ORG_PM_EMAIL_LIST: 'org.incident.pm-email-lists-destinations',
    ORG_PM_COMPLETED_EMAIL: 'org.incident.pm-email-lists',
    ORG_PM_EMAIL_DAY: 'org.incident.pm-email-lists-day',
    ORG_PM_EMAIL_TIME: 'org.incident.pm-email-lists-time',
    ORG_COMMENTS_NOTIFY_MENTIONS: 'org.comments.notify-mentions',
    PROFILE_USERNAME: 'usr.username',
    PROFILE_TIMEZONE: 'timezone',
    SECURITY_STORE_FILES: 'org.security.store-files',
    SECURITY_NLP: 'org.security.dialogflow-nlp',
    SECURITY_XSS_IN_POSTMORTEM: 'org.security.detect-xss-in-postmortem',
    WEBHOOK_CREATED_URL: 'org.webhooks.incident-created-callback-url',
    WEBHOOK_RESOLVED_URL: 'org.webhooks.incident-resolved-callback-url',
    WEBHOOK_COMLPETED_URL: 'org.webhooks.postmortem-complete-callback-url',
    GOTOMEETING_ENABLED: 'org.integration.gotomeeting-enabled',
    GOTOMEETING_AUTHORIZED: 'org.integration.gotomeeting-authorized',
    GOTOMEETING_CONSUMER_KEY: 'org.integration.gotomeeting-consumer-key',
    GOTOMEETING_CONSUMER_SECRET: 'org.integration.gotomeeting-consumer-secret',
    GOTOMEETING_COORGANIZER: 'org.integration.gotomeeting-coorganizer',
    SPLUNK_ENABLED: 'enabled',
    SPLUNK_MODE: 'mode',
    SPLUNK_URI: 'url',
    SPLUNK_TOKEN: 'accessToken',
    SUMOLOGIC_ENABLED: 'enabled',
    SUMOLOGIC_URL: 'apiUrl',
    SUMOLOGIC_USERNAME: 'username',
    SUMOLOGIC_PASSWORD: 'password',
    WEBHOOK_DESCRIPTION: 'webhookDescription',
    WEBHOOK_VERSION: 'webhookVersion',
    INCIDENT_RETROSPECTIVE_CUSTOM_TABS: 'org.incident.retrospective.custom-tabs',

    // Runbooks fields
    RUNBOOK_TASK_SCRIPT: 'script',
    RUNBOOK_TASK_THEME: 'theme',
    RUNBOOK_TASK_LANGUAGE: 'language',
    RUNBOOK_TASK_BODY: 'body',
    RUNBOOK_TASK_DESCRIPTION: 'description',
    RUNBOOK_TASK_LINK: 'link',
    RUNBOOK_TASK_ASYNC: 'async',
    RUNBOOK_TASK_METHOD: 'method',
    RUNBOOK_TASK_HOST: 'host',
    RUNBOOK_TASK_PORT: 'port',
    RUNBOOK_TASK_USER: 'user',
    RUNBOOK_TASK_PASSWORD: 'password',
    RUNBOOK_TASK_COMMANDS: 'commands',
    RUNBOOK_TASK_STDOUT: 'stdout',
    RUNBOOK_TASK_HEADERS: 'headers',
    RUNBOOK_TASK_URL: 'url',
    RUNBOOK_TASK_UISCHEMA: 'uiSchema',
    RUNBOOK_TASK_JSONSCHEMA: 'jsonSchema',
    RUNBOOK_TASK_SECONDS: 'seconds',
    RUNBOOK_TASK_FLOWID: 'flowId',
    RUNBOOK_TASK_GLOBAL: 'global',
    RUNBOOK_TASK_STATUS: 'status',
    RUNBOOK_TASK_STATUS_TEXT: 'statusText',
    RUNBOOK_TASK_STOP_ON_FAILURE: 'stopOnFailure',

    // SSO fields
    SSO_ENABLED: 'enabled',
    SSO_ONLY: 'ssoOnly',
    SSO_CERTIFICATE: 'signingCert',
    SSO_SIGN_IN_URL: 'signInEndpoint'
};

interface IUiSchemaWidgets {
    BASE_INPUT: string;
    CHECKBOX_WIDGET: string;
    SELECT_WIDGET: string;
    TEXTAREA_WIDGET: string;
    LINK_WIDGET: string;
}

export const UI_SCHEMA_WIDGETS: IUiSchemaWidgets = {
    BASE_INPUT: 'BaseInput',
    CHECKBOX_WIDGET: 'CheckboxWidget',
    SELECT_WIDGET: 'SelectWidget',
    TEXTAREA_WIDGET: 'TextareaWidget',
    LINK_WIDGET: 'LinkWidget'
};

interface IUiSchemaFields {
    SELECT_FIELD: string;
    DATE_TIME_FIELD: string;
    INPUT_LIST_FIELD: string;
    INPUT_TAGS_FIELD: string;
    CATEGORIES_FIELD: string;
    ENCRYPTED_FIELD: string;
    JSON_EDITOR_FIELD: string;
    API_AUTOCOMPLETE_FIELD: string;
    AUTOCOMPLETE_FIELD: string;
    SIMPLE_MARKDOWN_FIELD: string;
    INCIDENT_CHECKLIST_FIELD: string;
    JIRA_PROJECTS_FIELD: string;
    JIRA_FOLLOWUP_PROJECTS_FIELD: string;
    JIRA_FOLLOWUP_CREATE_PROJECTS_FIELD: string;
    MS_TEAMS_GROUP_FIELD: string;
    MS_TEAMS_CHANNEL_FIELD: string;
    INCIDENT_INVITED_MS_TEAMS_USERS_FIELD: string;
    JIRA_ISSUE_TYPES_FIELD: string;
    JIRA_FOLLOW_UP_ISSUE_TYPES_FIELD: string;
    JIRA_FOLLOW_UP_CREATE_ISSUE_TYPES_FIELD: string;
    JIRA_REQUIRED_FIELDS_FIELD: string;
    JIRA_ASSIGNEE_FIELD: string;
    USER_SELECT_FIELD: string;
    PAGER_DUTY_SELECT_FIELD: string;
    SERVICENOW_SERVICE_SELECT_FIELD: string;
    BACKSTAGE_SERVICE_SELECT_FIELD: string;
    BACKSTAGE_SERVICE_SELECT_TYPE_FIELD: string;
    OPSGENIE_SERVICE_SELECT_FIELD: string;
    CODE_SUGGESTION_FIELD: string;
    CODE_EDITOR_FIELD: string;
    SIMPLE_CODE_FIELD: string;
    TEXT_EDITOR_FIELD: string;
    READONLY_TEXT_FIELD: string;
    ARRAY_FIELD: string;
    OBJECT_BODY_FIELD: string;
    OBJECT_KEY_LIST_FIELD: string;
    MENTION_INPUT_FIELD: string;
    SLACK_USERS_FIELD: string;
}

export const UI_SCHEMA_FIELDS: IUiSchemaFields = {
    SELECT_FIELD: 'SelectField',
    DATE_TIME_FIELD: 'DateTimeField',
    INPUT_LIST_FIELD: 'InputListField',
    INPUT_TAGS_FIELD: 'InputTagsFeild',
    CATEGORIES_FIELD: 'CategoriesField',
    ENCRYPTED_FIELD: 'EncryptedField',
    JSON_EDITOR_FIELD: 'JsonEditorField',
    API_AUTOCOMPLETE_FIELD: 'ApiAutocompleteField',
    AUTOCOMPLETE_FIELD: 'AutocompleteField',
    SIMPLE_MARKDOWN_FIELD: 'SimpleMarkdownField',
    INCIDENT_CHECKLIST_FIELD: 'IncidentChecklistField',
    MS_TEAMS_GROUP_FIELD: 'MsTeamsGroupField',
    MS_TEAMS_CHANNEL_FIELD: 'MsTeamsChannelField',
    INCIDENT_INVITED_MS_TEAMS_USERS_FIELD: 'MsTeamsUsersField',
    JIRA_PROJECTS_FIELD: 'JiraProjectsField',
    JIRA_FOLLOWUP_PROJECTS_FIELD: 'JiraFollowupProjectsField',
    JIRA_FOLLOWUP_CREATE_PROJECTS_FIELD: 'JiraFollowupCreateProjectsField',
    JIRA_ISSUE_TYPES_FIELD: 'JiraIssueTypesField',
    JIRA_FOLLOW_UP_ISSUE_TYPES_FIELD: 'JiraFollowUpIssueTypesField',
    JIRA_FOLLOW_UP_CREATE_ISSUE_TYPES_FIELD: 'JiraFollowUpCreateIssueTypesField',
    JIRA_REQUIRED_FIELDS_FIELD: 'JiraRequiredFieldsField',
    JIRA_ASSIGNEE_FIELD: 'JiraAssigneeField',
    USER_SELECT_FIELD: 'UserSelectField',
    PAGER_DUTY_SELECT_FIELD: 'PagerDutySelectField',
    OPSGENIE_SERVICE_SELECT_FIELD: 'OpsgenieServiceSelectField',
    SERVICENOW_SERVICE_SELECT_FIELD: 'ServiceNowServiceSelectField',
    BACKSTAGE_SERVICE_SELECT_FIELD: 'BackstageServiceSelectField',
    BACKSTAGE_SERVICE_SELECT_TYPE_FIELD: 'BackstageServiceSelectTypeField',
    CODE_SUGGESTION_FIELD: 'CodeSuggestionField',
    CODE_EDITOR_FIELD: 'CodeEditorField',
    SIMPLE_CODE_FIELD: 'SimpleCodeField',
    TEXT_EDITOR_FIELD: 'TextEditorField',
    READONLY_TEXT_FIELD: 'ReadonlyTextField',
    ARRAY_FIELD: 'ArrayField',
    OBJECT_BODY_FIELD: 'ObjectBodyField',
    OBJECT_KEY_LIST_FIELD: 'ObjectKeyListField',
    MENTION_INPUT_FIELD: 'MentionInputField',
    SLACK_USERS_FIELD: 'SlackUsersField'
};

export enum FieldNames {
    date = 'date',
    input = 'input',
    toggle = 'toggle',
    textarea = 'textarea',
    dropdown = 'dropdown',
    multiselect = 'multiselect',
    datetimelocal = 'datetime-local',
    autocomplete = 'input-autocomplete'
}

export const BACKEND_FIELD_MAPPINGS = {
    [FieldNames.input]: UI_SCHEMA_WIDGETS.BASE_INPUT,
    [FieldNames.textarea]: UI_SCHEMA_WIDGETS.BASE_INPUT,
    [FieldNames.dropdown]: UI_SCHEMA_FIELDS.SELECT_FIELD,
    [FieldNames.multiselect]: UI_SCHEMA_FIELDS.SELECT_FIELD,
    [FieldNames['datetime-local']]: UI_SCHEMA_FIELDS.DATE_TIME_FIELD,
    [FieldNames.date]: UI_SCHEMA_FIELDS.DATE_TIME_FIELD,
    [FieldNames.autocomplete]: UI_SCHEMA_FIELDS.API_AUTOCOMPLETE_FIELD
};

export const URI_PATTERN =
    '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,})';
export const BLANK_SPACES_PATTERN = '(?!^ +$)^.+$';

export const UI_MULTIPLE_SELECT = 'ui:multipleSelect';
export const UI_HALFWIDTH = 'ui:halfWidth';

export const REQUIRED_FIELDS_TYPES = {
    incident: 'org.jira.incident_required_field',
    followup: 'org.jira.follow_up_required_field',
    followup_create: 'org.jira'
};

export const REQUIRED_FIELD_TYPE_INCIDENT = 'incident';
export const REQUIRED_FIELD_TYPE_FOLLOWUP = 'followup';
export const REQUIRED_FIELD_TYPE_FOLLOWUP_CREATE = 'followup_create';
