import { $api_workflows_v2 } from 'src/utils/apis/v2';
import { createActionAsync } from 'redux-act-async';
import { createAction } from 'redux-act';

export const clearWorkflows = createAction('CLEAR_WORKFLOWS');

// Workflows
export const createWorkflow = createActionAsync(
    'CREATE_WORKFLOW',
    (data: Pick<IWorkflows.IWorkflow, 'name' | 'active'>) =>
        $api_workflows_v2
            .post({ data })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const getWorkflows = createActionAsync('GET_WORKFLOWS', (offset: number, limit: number) =>
    $api_workflows_v2
        .get({ params: { offset, limit } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getWorkflowsStats = createActionAsync('GET_WORKFLOWS_STATS', (workflowIds: string[]) =>
    $api_workflows_v2
        .get({ url: `/statistics?id=${workflowIds.join('&id=')}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateWorkflow = createActionAsync(
    'UPDATE_WORKFLOW',
    (workflowId: string, data: Pick<IWorkflows.IWorkflow, 'name' | 'active'>) =>
        $api_workflows_v2
            .put({ url: `/${workflowId}`, data })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

// Worflow Triggers
export const createWorkflowTrigger = createActionAsync(
    'CREATE_WORKFLOW_TRIGGER',
    (workflowId: string, workflowTrigger: IWorkflows.IWorkflowTrigger) =>
        $api_workflows_v2
            .post({ url: `/${workflowId}/triggers`, data: workflowTrigger })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const updateWorkflowTrigger = createActionAsync(
    'UPDATE_WORKFLOW_TRIGGER',
    (workflowId: string, workflowTrigger: IWorkflows.IWorkflowTrigger) =>
        $api_workflows_v2
            .put({ url: `/${workflowId}/triggers/${workflowTrigger.id}`, data: workflowTrigger })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

// Workflow Steps
export const updateWorkflowSteps = createActionAsync(
    'UPDATE_WORKFLOW_STEPS',
    (workflowId: string, steps: IWorkflows.IWorkflowStep[]) =>
        $api_workflows_v2
            .put({ url: `/${workflowId}/steps`, data: { steps } })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);
